<template>
  <type-page :config="orgList" />
</template>

<script>
import { orgList } from '@/data_conf/org'
import TypePage from '@/components/datainfo/TypePage.vue'

export default {
  components: {
    TypePage
  },
  data () {
    return {
      orgList: orgList({
        openConf: {
          position: 'left',
          isPop: true
        }
      })
    }
  }
}
</script>
