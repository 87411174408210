<style scoped lang="less">
  .type-page {
    nav {
      padding: 25px;
      background-color: #FFF;
    }
    .content {
      margin: 20px 0 0 20px;
      background-color: #FFF;
    }
    .expanded-list {
      margin-top: 20px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      & > * {
        color: rgba(0, 0, 0, .65);
        cursor: pointer;
        transition: all .3s;
        padding: 0 10px;
        line-height: 30px;
        &:hover {
          color: #2F5BEA;
        }
      }
    }
  }
</style>

<template>
  <div class="type-page">
    <nav>
      <!-- <fm-breadcrumb>
        <fm-breadcrumb-item>系统</fm-breadcrumb-item>
        <fm-breadcrumb-item>{{showTitle}}</fm-breadcrumb-item>
      </fm-breadcrumb> -->
      <div style="margin-top: 10px;display: flex;align-items: center;">
        <fm-select filterable placeholder="请选择" @change="onSelectChange">
          <fm-option v-for="item in dataList" :key="item.id" :value="item.id" :label="config.listConfig.getTitle(item, dataParm)"></fm-option>
        </fm-select>
        <fm-btn
          tint type="primary"
          v-for="item in config.titleConfig.getTitleMenus ? config.titleConfig.getTitleMenus(dataList) : config.titleConfig.titleMenus"
          style="margin-left: 10px;"
          :key="item.key"
          @click="clickTitleMenu(item)">
          <i v-if="item.key === 'add'" class="iconfont icon-add"></i>
          {{item.label}}
        </fm-btn>
        <fm-btn @click="expanded = !expanded" style="margin-left: 10px;" text><i class="iconfont icon-zhankai"></i>{{expanded ? '收起' : '展开'}}</fm-btn>
      </div>
      <div class="expanded-list" slot="content" v-show="expanded">
        <div
          v-for="item in dataList" :key="item.id"
          @click="onSelectChange(item.id)">
          {{config.listConfig.getTitle(item, dataParm)}}
        </div>
      </div>
    </nav>
    <div class="content">
      <data-item-detail
        v-if="chooseData"
        :title="showTitle"
        :list-menus="config.listConfig.getListMenus ? config.listConfig.getListMenus(dataList) : config.listConfig.listMenus"
        @clickListMenu="clickListMenu"
        :data="chooseData"
        :direction="openConf.position === 'left' ? 'row' : 'column'"
        :config="config.listConfig.config">
      </data-item-detail>
    </div>
    <data-update
      :title-text="config.updateConfig.title"
      :open-dialog.sync="openDialogEdit"
      :open-dialog-del.sync="openDialogDel"
      :request="config.updateConfig.request"
      :his-data="hisData"
      @updateOver="loadData"
      @handleClose="openDialogEdit = false;openDialogDel = false"
      :form-parms="formParms">
    </data-update>
  </div>
</template>

<script>
import DataItemDetail from './DataItemDetail.vue'
export default {
  name: 'TypePage',
  inject: {
    parentLevel: {
      default: []
    }
  },
  components: {
    DataItemDetail
  },
  props: {
    dataParm: {
      type: Object
    },
    config: {
      type: Object
    }
  },
  async created () {
    this.openConf = this.config.openConf ||  this.openConf
    this.otherData = this.config.init ? await this.config.init(this.dataParm) : {}
    this.ready = new Promise((r) => this.resolve = r)
    this.loadData()
  },
  watch: {
    routerActiveId: {
      immediate: true,
      async handler (v) {
        if (v && this.activeId !== v) {
          this.activeId = v
          await this.ready
          this.onSelectChange(this.activeId)
        }
      }
    },
    dataParm () {
      this.ready = new Promise((r) => this.resolve = r)
      this.loadData()
    }
  },
  data () {
    let resolve = null
    let ready = new Promise(function (r) {
      resolve = r
    })
    return {
      activeId: null,
      resolve: resolve,
      ready: ready,
      expanded: true,
      level: [],
      openConf: {
        position: 'bottom',
        isPop: false
      },
      chooseData: null,
      openDetail: false,
      choose: null,
      loading: false,
      dataList: [],
      otherData: {},
      formParms: [],
      hisData: null,
      openDialogEdit: false,
      openDialogDel: false,
      openDialogFile: false,
      filterData: null
    }
  },
  provide () {
    if (!this.parentLevel) {
      this.level = [this.config.key || 'no_key']
    } else {
      this.level = this.parentLevel.concat([this.config.key || 'no_key'])
    }
    return {
      parentLevel: this.level
    }
  },
  computed: {
    routerActiveId () {
      return this.$route.params.id ? this.$route.params.id : null
    },
    showTitle () {
      return this.chooseData ? this.config.listConfig.getTitle(this.chooseData, this.dataParm) : '未选择'
    }
  },
  methods: {
    onSelectChange (id) {
      this.expanded = false
      this.chooseData = this.dataList.find(v => v.id === id)
    },
    handleClose () {
      this.openDetail = false
    },
    async clickTitleMenu (i) {
      let key = i.key
      if (key === 'add') {
        this.hisData = this.config.getHisData ? this.config.getHisData(this.dataParm) : {}
        let data = this.config.updateConfig.getFormParms(this.otherData, 'add', this.dataParm)
        if (data && data.then) {
          this.formParms = await data
        } else {
          this.formParms = data
        }
        this.openDialogEdit = true
      } else if (i.dealFun) {
        await i.dealFun(this)
      }
    },
    async clickListMenu (i, data) {
      this.hisData = data
      let key = i.key
      if (key === 'edit') {
        let data1 = this.config.updateConfig.getFormParms(this.otherData, 'edit')
        if (data1 && data1.then) {
          this.formParms = await data1
        } else {
          this.formParms = data1
        }
        this.openDialogEdit = true
      } else if (key === 'del') {
        this.openDialogDel = true
      } else if (i.dealFun) {
        await i.dealFun(data, this)
      }
    },
    async loadData () {
      this.dataList = await this.config.getData(this.dataParm)
      if (this.dataList.length) {
        this.onSelectChange(this.dataList[0].id)
      }
      this.resolve(this.dataList)
      this.$emit('dataChange', this.dataList.map(v => v))
    },
    checked (data) {
      this.$emit('checked', data)
    }
  }
}
</script>
