import store from '@/store'

import TextInfo from '@/components/datainfo/TextInfo'
import TypeItem from '@/components/datainfo/TypeItem'
import FileInfo from '@/components/datainfo/FileInfo'

import fileConf from '@/syslib/file_conf'

import {
  sysInstanceList
} from './sys_instance'

import {
  orgRequest
} from '@/api'

let formParms = [{
  type: 'input',
  label: '名称',
  key: 'name',
  check: {
    required: true
  }
},
{
  type: 'input',
  label: '全称',
  key: 'fullName'
},
{
  type: 'textarea',
  label: '备注',
  key: 'remark'
}]

export const orgList = function (parm = {}) {
  let config = {
    key: parm.key || 'org',
    titleConfig: {
      title: parm && parm.title ? parm.title : '客户',
      titleMenus: parm && parm.titleMenus ? parm.titleMenus : [{
        key: 'add',
        label: '新增'
      }]
    },
    listConfig: {
      filterFun (data, parm) {
        return data.filter((v) => {
          let need = parm ? v.name.includes(parm) : true
          return need
        })
      },
      listMenus: parm && parm.listMenus ? parm.listMenus : [
        {
          key: 'task',
          label: '相关任务',
          dealFun (data, vm) {
            vm.$router.push({
              name: 'task', query: {
                type: 'org',
                id: data.id
              }
            })
          }
        },
        {
          key: 'edit',
          label: '修改'
        },
        {
          key: 'del',
          label: '删除'
        }
      ],
      config: org(),
      getTitle: (data) => {
        return data.name + ' ' + (data.remark || '')
      }
    },
    async getData (dataParm) {
      let data = await orgRequest.get({id: dataParm && dataParm.id ? dataParm.id : null})
      if (!dataParm) {
        store.dispatch('setOrgList', data)
      } else {
        store.dispatch('loadOrgList', true)
      }
      return data
    },
    updateConfig: {
      title: '客户',
      request: orgRequest,
      getFormParms () {
        return formParms
      }
    }
  }
  if (parm.openConf) {
    config.openConf = parm.openConf
  }
  return config
}

export const org = function ({defaultOpen} = {}) {
  let config = {
    defaultOpen: defaultOpen || false,
    getTitle: (data) => {
      return data ? data.name + ' ' + (data.remark || '') : ''
    },
    async getData (dataParm) {
      let data = await orgRequest.get({id: dataParm && dataParm.id ? dataParm.id : null})
      return data && data.length === 1 ? data[0] : null
    },
    getInfoParm: (data) => {
      return data ? [{
        comp: TextInfo,
        config: {
          infoParm: formParms.filter(v => v.key !== 'name').map((v) => {
            return {
              label: v.label,
              field: v.key
            }
          })
        }
      },
      {
        comp: TypeItem,
        dataParm: {
          useOrgId: data.id
        },
        config: sysInstanceList()
      },
      {
        comp: FileInfo,
        config: {
          filePath: fileConf.org ? fileConf.org.getPath(data) : null
        }
      }] : []
    }
  }
  return config
}
